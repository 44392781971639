<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />

      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-8>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-end>
          <v-flex xl3 lg2 md3 pl-6 pr-6 pt-3 sm3 xs12 align-self-center>
            <v-autocomplete
              solo
              dense
              color="black"
              :items="category"
              v-model="categoryId"
              item-text="name"
              item-value="_id"
              item-color="#FF1313"
              hide-details
              clearable
              label="Category"
              class="custom-autocomplete rounded-lg mainfont"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                  >Category</span
                >
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xl3 lg2 md3 pl-2 pr-6 pt-3 sm3 xs12 align-self-center>
            <v-autocomplete
              solo
              dense
              color="black"
              :items="itemArray2"
              v-model="status"
              item-text="name"
              item-color="#FF1313"
              hide-details
              clearable
              label="Role"
              class="custom-autocomplete rounded-lg"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="mainfont"
                  style="color: black; font-size: 14px"
                  >Status</span
                >
              </template>
            </v-autocomplete>
          </v-flex>
        </v-layout>

        <v-layout
          pl-6
          wrap
          class="mainfont"
          style="color: white; font-size: 16px; font-weight: lighter"
        >
          <v-flex pt-4 pr-4 xs6 v-for="(item, i) in list" :key="i">
            <v-card style="border-radius: 15px">
              <v-layout wrap justify-center>
                <v-flex xs1 pl-6 pt-5 pb-5 align-self-center>
                  <v-avatar size="80px">
                    <v-img :src="mediaURLnew + item.photos[0]"> </v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs5 text-center align-self-center>
                  <v-layout wrap fill-height justify-center>
                    <v-flex
                      pl-8
                      xs7
                      v-if="item"
                      text-left
                      class="mainfont"
                      style="color: black; font-size: 15px; font-weight: bold"
                    >
                      <span v-if="item.categoryId">
                        <span v-if="item.categoryId.name">
                          {{ item.categoryId.name }}
                        </span>
                      </span>
                    </v-flex>
                    <v-flex
                      pl-8
                      xs7
                      v-if="item"
                      text-left
                      class="mainfont"
                      style="color: black; font-size: 13px; font-weight: lighter"
                    >
                      <span v-if="item">
                        <span v-if="item.name">
                          {{ item.name }}
                        </span>
                      </span>
                    </v-flex>

                    <v-flex
                      pl-8
                      xs7
                      v-if="item"
                      text-left
                      class="mainfont"
                      style="color: black; font-size: 13px"
                    >
                      {{ item.weight }} &nbsp;{{ item.weightType
 }}
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3 pl-6 text-center align-self-center>
                  <v-layout wrap fill-height>
                    <v-flex
                      xs10
                      text-left
                      class="mainfont"
                      style="color: #13736f; font-size: 16px; font-weight: bold"
                      >Rs&nbsp;{{ item.price }}</v-flex
                    >
                  </v-layout>
                </v-flex>
                <v-flex xs3 text-center align-self-center>
                  <v-layout wrap fill-height>
                    <v-flex
                      pt-2
                      xs6
                      align-self-center
                      class="mainfont"
                      style="color: #797979; font-size: 13px; font-weight: bold"
                    >
                      STATUS</v-flex
                    >

                    <v-flex xs6 pb-4 text-left>
                      <v-switch
                        v-if="item.status === 'Active'"
                        v-model="switch1"
                        color="#13736f"
                        @click="changeStatus(item._id, 'Inactive', 'switch1')"
                        :hide-details="true"
                      ></v-switch>

                      <v-switch
                        v-else-if="item.status === 'Inactive'"
                        color="#13736f"
                        v-model="switch2"
                        @click="changeStatus(item._id, 'Active', 'switch2')"
                        :hide-details="true"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs6 align-self-center pl-6>
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        elevation="0"
                        color="#EFFFFA"
                        @click="(editdailog = true), (curid = item)"
                      >
                        <v-icon color="black" size="130%" dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn></v-flex
                    >
                    <v-flex xs6 align-self-center>
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        elevation="0"
                        color="#EFFFFA"
                        @click="(deletedialog = true), (curid = item._id)"
                      >
                        <v-icon color="red" dark size="130%">
                          mdi-delete
                        </v-icon>
                      </v-btn></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout pt-4 pb-5 wrap justify-center>
        <v-flex xs12 align-self-center>
          <div>
            <v-pagination
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#005f32"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Are you sure you want to delete this Product?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="editdailog"
      max-width="650px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex
            ><v-layout style="border-radius: 20px" pa-4 wrap justify-center>
              <v-flex xs12 text-right>
                <v-icon
                  @click="editdailog = false"
                  color="red"
                  size="150%"
                  dark
                >
                  mdi-close
                </v-icon>
              </v-flex>
              <v-flex  v-if="curid.categoryId && curid.categoryId.name" xs10 pt-4 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Category  </span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                  disabled
                    color="#13736f"
                    placeholder="Name"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.categoryId.name"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex xs10 pt-4 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Category Name </span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="#13736f"
                    placeholder="Name"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.name"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs10 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Price (Rs)</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="#13736f"
                    placeholder="Listing Order"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.price"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>
              <v-flex pt-2 xs6 class="mainfont">
                
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Weight</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="#13736f"
                    placeholder=" weight"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.weight"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs4 pt-2 pl-4 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Unit</span
                >
                      <v-autocomplete
                        outlined
                        dense
                        color="black"
                        :items="itemArray3"
                        v-model="curid.weightType"
                        item-text="name"
                        item-color="#FF1313"
                        hide-details
                        clearable
                        class="custom-autocomplete  mainfont"
                      >
                        <template v-slot:append>
                          <v-icon color="black">mdi-chevron-down</v-icon>
                        </template>
                      </v-autocomplete>
                    </v-flex>
              <v-flex pt-2 xs10 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Stock</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="#13736f"
                    placeholder=" stock"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.stock"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs10 class="mainfont">
                <span
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                  >Description</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="#13736f"
                    placeholder=" stock"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.description"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex
                xs10
                pt-4
                class="mainfont"
                style="color: black; font-size: 16px; font-weight: bold"
              >
                <ImageComp
                  :singleImage="curid.photos"
                  @stepper="winStepper"
                  :height="'800'"
                  :width="'1300'"
                  :heading="'Upload Image'"
                  :componentType="'topImage'"
                />
              </v-flex>

              <v-flex xs10 text-right pt-4>
                <v-btn dark width="100px" color="#13736f" @click="edit()">
                  <span class="mainfont" style="color: white; font-size: 14px">
                    Edit
                  </span>
                  <v-icon size="15px" color="white">mdi-pencil</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  
  
  <script>
import axios from "axios";
import ImageComp from "@/components/Common/singleImage";
import { mdiChevronDown } from "@mdi/js";

export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      preview: null,
      link: null,
      prev: null,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      switch1: true,
      switch2: false,
      category: [],
      currentPage: 1,
      url: null,
      categoryName: null,
      appLoading: false,
      name: null,
      categoryId: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      itemArray2: ["Active", "Inactive"],
      itemArray3: ["kg", "g"],

      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
      status:null,
    };
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
  },
  watch: {
    currentPage() {
      this.getList();
    },
    categoryId() {
      this.getList();
    },
    status() {
      this.getList();
    },
  },
  methods: {
    changeStatus(id, newStatus, switchName) {
      this.appLoading = true;
      axios({
        url: "/admin/product/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: id,
          status: newStatus,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getList();
            this[switchName] = !this[switchName];
          } else {
            this.showSnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.error(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "topImage") {
        this.topImage = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.image = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      }
    },
    deleteItem() {
      axios({
        url: "/admin/product/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
  this.formData2.append("id", this.curid._id);
  this.formData2.append("weight", this.curid.weight);
  this.formData2.append("price", this.curid.price);
  this.formData2.append("name", this.curid.name);
  this.formData2.append("stock", this.curid.stock);
  this.formData2.append("description", this.curid.description);
  this.formData2.append("photos", this.topImage);
  this.formData2.append("weightType", this.curid.weightType);


  
  axios({
    method: "POST",
    url: "/admin/product/edit",
    headers: {
      token: localStorage.getItem("token"),
    },
    data: this.formData2,
  })
    .then((response) => {
      if (response.data.status == true) {
        this.editdailog = false;
        this.msg = response.data.msg;
        this.showSnackBar = true;
        location.reload();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        location.reload();
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},

    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/admin/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          status: this.status,
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          limit: 10,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.cus {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.custom-switch .v-input--selection-controls__input {
  height: 10px; /* Adjust the height as per your requirement */
  width: 10px; /* Adjust the width as per your requirement */
  margin-top: 0px; /* Adjust the margin-top as per your requirement for vertical alignment */
}
</style>