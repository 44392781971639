<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />

      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-6>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-3 fill-height>
          <v-flex xs12 class="mainbg2" pt-3>
            <template class="mainbg2">
              <v-layout wrap pl-6 justify-start>
                <v-flex xs5 style="border-radius: 8px" class="cus3">
                  <v-tabs
                    v-model="subtab"
                    :show-arrows="false"
                    color="#13736f"
                    background-color="transparent"
                    text-center
                    dark
                  >
                    <v-tab to="#tabs-3"
                      ><span class="fontpop2"> &nbsp;&nbsp;Placed</span></v-tab
                    >

                    <v-tab to="#tabs-5" @change="cancelledList()"
                      ><span class="fontpop2">&nbsp;Cancelled</span></v-tab
                    >
                    <v-tab to="#tabs-6" @change="shippedList()"
                      ><span class="fontpop2">&nbsp;&nbsp; Shipped</span></v-tab
                    >
                    <v-tab to="#tabs-7" @change="deliveredList()"
                      ><span class="fontpop2"
                        >&nbsp;&nbsp; Delivered</span
                      ></v-tab
                    >
                  </v-tabs>
                </v-flex>

                <v-flex xs7 pt-2>
                  <v-layout wrap justify-end pr-6>
                    <!-- <v-flex xs3>
                      <v-autocomplete
                      
                        solo
                        dense
                        color="#13736f"
                        item-value="_id"
                        :items="category"
                        item-text="name"
                        item-color="#FF1313"
                        hide-details
                        clearable
                        label=" Category"
                        background-color="white"
                        class="custom-autocomplete rounded-lg dropfont"
                      >
                        <template v-slot:append>
                          <v-icon color="black">mdi-chevron-down</v-icon>
                        </template>
                        <template v-slot:label>
                          <span class="custom-label-text mainfont"   style="color: black; font-size: 14px"> Category</span>
                        </template>
                      </v-autocomplete>
                    </v-flex> -->

                    <v-flex xs4 pl-7>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        max-width="290"
                        color="#13736f"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            dense
                            solo
                            label="From Date"
                            readonly
                            v-model="fromDate"
                            v-bind="attrs"
                            background-color="white"
                            color="#13736f"
                            class="rounded-lg dropfont"
                            v-on="on"
                            @click:clear="from = null"
                          >
                            <template v-slot:label>
                              <span
                                class="custom-label-text"
                                style="color: black; font-size: 14px"
                              >
                                From Date</span
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromDate"
                          color="#13736f"
                          @change="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs4 pl-6>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            label="To Date"
                            readonly
                            dense
                            background-color="white"
                            color="#13736f"
                            class="rounded-lg fontpop2"
                            solo
                            v-model="toDate"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="from = null"
                          >
                            <template v-slot:label>
                              <span
                                class="custom-label-text"
                                style="color: black; font-size: 14px"
                              >
                                To Date</span
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="toDate"
                          color="#13736f"
                          @change="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-tabs-items v-model="subtab" id="custom-tab-items">
                <v-tab-item value="tabs-3" class="mainbg2">
                  <v-layout
                    justify-center
                    wrap
                    v-if="placedorderlist.length > 0"
                  >
                    <v-flex
                      pb-4
                      v-for="(item, i) in placedorderlist"
                      :key="i"
                      xs12
                      pl-6
                      pr-6
                      align-self-center
                    >
                      <v-card style="border-radius: 15px">
                        <v-layout wrap justify-center>
                          <v-flex xs1 pl-6 pt-5 pb-5 align-self-center>
                            <!-- <v-carousel
:show-arrows="false"
cycle
:interval="2000"
height="80"
hide-delimiters
style="width:100px"

> -->
                            <!-- <v-carousel-item
v-for="(item2, j) in item.itemPhotos"
:key="j"
> -->
                            <v-avatar size="80px">
                              <v-img :src="mediaURLnew + item.itemPhotos[0]">
                              </v-img>
                            </v-avatar>
                            <!-- </v-carousel-item>
</v-carousel> -->
                          </v-flex>
                          <v-flex xs9 pl-8 align-self-center>
                            <v-layout wrap>
                              <v-flex xs4 pb-1>
                                <span
                                  class="mainfont"
                                  style="
                                    color: black;
                                    font-size: 15px;
                                    font-weight: bold;
                                  "
                                >
                                  {{ item.itemNames }}
                                </span></v-flex
                              >

                              <v-flex xs4 text-left>
                                <span class="popfont"
                                  >ORDER ID {{ item.orderID }}
                                </span>
                              </v-flex>

                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">NAME </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">PHONE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">AMOUNT </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">ORDER DATE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span class="popfont">ORDER STATUS </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex
                                    xs2
                                    v-if="item.uid.role === 'Customer'"
                                  >
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.customerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Trader'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.traderId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Farmer'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.farmerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2>
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.phone }}
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="fontpop3"
                                        >Rs&nbsp;{{ item.orderTotal }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ formatDate(item.create_date) }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ item.orderStatus }}
                                      </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex text-left pt-6 xs2 align-self-center>
                            <v-layout wrap justify-center>
                              <v-flex
                                xs12
                                class="mainfont"
                                style="color: black; font-size: 14px"
                              >
                                <v-btn
                                  dark
                                  color="#13736f"
                                  width="80%"
                                  class="no-uppercase"
                                  @click="
                                    $router.push(
                                      '/StoreDetailedView?id=' + item._id
                                    )
                                  "
                                  ><span
                                    style="
                                      font-family: mainfont;
                                      font-size: 14px;
                                      color: white;
                                      letter-spacing: 1px;
                                      cursor: pointer;
                                    "
                                    >View
                                  </span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
                        <v-flex xs12 align-self-center>
                          <div>
                            <v-pagination
                              prev-icon="mdi-menu-left"
                              next-icon="mdi-menu-right"
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPage"
                              color="#13736f"
                            ></v-pagination>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-else>
                    <v-flex text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Placed Orders!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <v-tab-item value="tabs-5" class="mainbg2">
                  <v-layout justify-center wrap v-if="Cancelledlist.length > 0">
                    <v-flex
                      pb-4
                      v-for="(item, i) in Cancelledlist"
                      :key="i"
                      xs12
                      pl-6
                      pr-6
                      align-self-center
                    >
                      <v-card style="border-radius: 15px">
                        <v-layout wrap justify-center>
                          <v-flex xs1 pl-6 pt-5 pb-5 align-self-center>
                            <!-- <v-carousel
:show-arrows="false"
cycle
:interval="2000"
height="80"
hide-delimiters
style="width:100px"

> -->
                            <!-- <v-carousel-item
v-for="(item2, j) in item.itemPhotos"
:key="j"
> -->
                            <v-avatar size="80px">
                              <v-img :src="mediaURLnew + item.itemPhotos[0]">
                              </v-img>
                            </v-avatar>
                            <!-- </v-carousel-item>
</v-carousel> -->
                          </v-flex>
                          <v-flex xs9 pl-8 align-self-center>
                            <v-layout wrap>
                              <v-flex xs4 pb-1>
                                <span
                                  class="mainfont"
                                  style="
                                    color: black;
                                    font-size: 15px;
                                    font-weight: bold;
                                  "
                                >
                                  {{ item.itemNames }}
                                </span></v-flex
                              >

                              <v-flex xs4 text-left>
                                <span class="popfont"
                                  >ORDER ID {{ item.orderID }}
                                </span>
                              </v-flex>

                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">NAME </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">PHONE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">AMOUNT </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">ORDER DATE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span class="popfont">ORDER STATUS </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex
                                    xs2
                                    v-if="item.uid.role === 'Customer'"
                                  >
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.customerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Trader'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.traderId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Farmer'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.farmerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2>
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.phone }}
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="fontpop3"
                                        >Rs&nbsp;{{ item.orderTotal }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ formatDate(item.create_date) }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ item.orderStatus }}
                                      </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex text-left pt-6 xs2 align-self-center>
                            <v-layout wrap justify-center>
                              <v-flex
                                xs12
                                class="mainfont"
                                style="color: black; font-size: 14px"
                              >
                                <v-btn
                                  dark
                                  color="#13736f"
                                  width="80%"
                                  class="no-uppercase"
                                  @click="
                                    $router.push(
                                      '/StoreDetailedView?id=' + item._id
                                    )
                                  "
                                  ><span
                                    style="
                                      font-family: mainfont;
                                      font-size: 14px;
                                      color: white;
                                      letter-spacing: 1px;
                                      cursor: pointer;
                                    "
                                    >View
                                  </span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
                        <v-flex xs12 align-self-center>
                          <div>
                            <v-pagination
                              prev-icon="mdi-menu-left"
                              next-icon="mdi-menu-right"
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPageCancelled"
                              color="#13736f"
                            ></v-pagination>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-else>
                    <v-flex text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Cancelled Orders!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <v-tab-item value="tabs-6" class="mainbg2">
                  <v-layout justify-center wrap v-if="shippedlist.length > 0">
                    <v-flex
                      pb-4
                      v-for="(item, i) in shippedlist"
                      :key="i"
                      xs12
                      pl-6
                      pr-6
                      align-self-center
                    >
                      <v-card style="border-radius: 15px">
                        <v-layout wrap justify-center>
                          <v-flex xs1 pl-6 pt-5 pb-5 align-self-center>
                            <!-- <v-carousel
:show-arrows="false"
cycle
:interval="2000"
height="80"
hide-delimiters
style="width:100px"

> -->
                            <!-- <v-carousel-item
v-for="(item2, j) in item.itemPhotos"
:key="j"
> -->
                            <v-avatar size="80px">
                              <v-img :src="mediaURLnew + item.itemPhotos[0]">
                              </v-img>
                            </v-avatar>
                            <!-- </v-carousel-item>
</v-carousel> -->
                          </v-flex>
                          <v-flex xs9 pl-8 align-self-center>
                            <v-layout wrap>
                              <v-flex xs4 pb-1>
                                <span
                                  class="mainfont"
                                  style="
                                    color: black;
                                    font-size: 15px;
                                    font-weight: bold;
                                  "
                                >
                                  {{ item.itemNames }}
                                </span></v-flex
                              >

                              <v-flex xs4 text-left>
                                <span class="popfont"
                                  >ORDER ID {{ item.orderID }}
                                </span>
                              </v-flex>

                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">NAME </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">PHONE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">AMOUNT </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">ORDER DATE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span class="popfont">ORDER STATUS </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex
                                    xs2
                                    v-if="item.uid.role === 'Customer'"
                                  >
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.customerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Trader'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.traderId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Farmer'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.farmerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2>
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.phone }}
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="fontpop3"
                                        >Rs&nbsp;{{ item.orderTotal }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ formatDate(item.create_date) }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ item.orderStatus }}
                                      </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex text-left pt-6 xs2 align-self-center>
                            <v-layout wrap justify-center>
                              <v-flex
                                xs12
                                class="mainfont"
                                style="color: black; font-size: 14px"
                              >
                                <v-btn
                                  dark
                                  color="#13736f"
                                  width="80%"
                                  class="no-uppercase"
                                  @click="
                                    $router.push(
                                      '/StoreDetailedView?id=' + item._id
                                    )
                                  "
                                  ><span
                                    style="
                                      font-family: mainfont;
                                      font-size: 14px;
                                      color: white;
                                      letter-spacing: 1px;
                                      cursor: pointer;
                                    "
                                    >View
                                  </span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
                        <v-flex xs12 align-self-center>
                          <div>
                            <v-pagination
                              prev-icon="mdi-menu-left"
                              next-icon="mdi-menu-right"
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPageShipped"
                              color="#13736f"
                            ></v-pagination>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-else>
                    <v-flex text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Shipped Orders!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <v-tab-item value="tabs-7" class="mainbg2">
                  <v-layout justify-center wrap v-if="deliveredlist.length > 0">
                    <v-flex
                      pb-4
                      v-for="(item, i) in deliveredlist"
                      :key="i"
                      xs12
                      pl-6
                      pr-6
                      align-self-center
                    >
                      <v-card style="border-radius: 15px">
                        <v-layout wrap justify-center>
                          <v-flex xs1 pl-6 pt-5 pb-5 align-self-center>
                            <!-- <v-carousel
:show-arrows="false"
cycle
:interval="2000"
height="80"
hide-delimiters
style="width:100px"

> -->
                            <!-- <v-carousel-item
v-for="(item2, j) in item.itemPhotos"
:key="j"
> -->
                            <v-avatar size="80px">
                              <v-img :src="mediaURLnew + item.itemPhotos[0]">
                              </v-img>
                            </v-avatar>
                            <!-- </v-carousel-item>
</v-carousel> -->
                          </v-flex>
                          <v-flex xs9 pl-8 align-self-center>
                            <v-layout wrap>
                              <v-flex xs4 pb-1>
                                <span
                                  class="mainfont"
                                  style="
                                    color: black;
                                    font-size: 15px;
                                    font-weight: bold;
                                  "
                                >
                                  {{ item.itemNames }}
                                </span></v-flex
                              >

                              <v-flex xs4 text-left>
                                <span class="popfont"
                                  >ORDER ID {{ item.orderID }}
                                </span>
                              </v-flex>

                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">NAME </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">PHONE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">AMOUNT </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="popfont">ORDER DATE </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span class="popfont">ORDER STATUS </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex
                                    xs2
                                    v-if="item.uid.role === 'Customer'"
                                  >
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.customerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Trader'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.traderId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2 v-if="item.uid.role === 'Farmer'">
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.farmerId.name }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs2>
                                    <span
                                      class="mainfont"
                                      style="color: black; font-size: 14px"
                                      >{{ item.uid.phone }}
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span class="fontpop3"
                                        >Rs&nbsp;{{ item.orderTotal }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs2>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ formatDate(item.create_date) }}
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex xs3>
                                    <span>
                                      <span
                                        class="mainfont"
                                        style="color: black; font-size: 14px"
                                        >{{ item.orderStatus }}
                                      </span>
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex text-left pt-6 xs2 align-self-center>
                            <v-layout wrap justify-center>
                              <v-flex
                                xs12
                                class="mainfont"
                                style="color: black; font-size: 14px"
                              >
                                <v-btn
                                  dark
                                  color="#13736f"
                                  width="80%"
                                  class="no-uppercase"
                                  @click="
                                    $router.push(
                                      '/StoreDetailedView?id=' + item._id
                                    )
                                  "
                                  ><span
                                    style="
                                      font-family: mainfont;
                                      font-size: 14px;
                                      color: white;
                                      letter-spacing: 1px;
                                      cursor: pointer;
                                    "
                                    >View
                                  </span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
                        <v-flex xs12 align-self-center>
                          <div>
                            <v-pagination
                              prev-icon="mdi-menu-left"
                              next-icon="mdi-menu-right"
                              :length="pages"
                              :total-visible="7"
                              v-model="currentPageDelivered"
                              color="#13736f"
                            ></v-pagination>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-else>
                    <v-flex text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Delivered Orders!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  
  <script>
import axios from "axios";
import { mdiChevronDown } from "@mdi/js";

export default {
  data() {
    return {
      preview: null,
      fromDate: null,
      toDate: null,
      menu1: false,
      menu2: false,
      link: null,
      prev: null,
      page: 1,
      currentPage: 1,
      currentPageCancelled: 1,
      currentPageShipped: 1,
      currentPageDelivered: 1,

      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      shippedlist: [],
      deliveredlist: [],
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      isInitialLoad: true,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      Cancelledlist: [],
      subtab: null,
      placedorderlist: [],
      editdailog: false,
      category: [],
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    currentPageCancelled() {
      this.cancelledList();
    },
    currentPageShipped() {
      this.shippedList();
    },
    currentPageDelivered() {
      this.deliveredList();
    },
    fromDate() {
      this.getList();
      this.cancelledList();
      this.shippedList();
      this.deliveredList();
    },
    toDate() {
      this.getList();
      this.cancelledList();
      this.shippedList();
      this.deliveredList();
    },
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/orders/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderStatus: "Placed",
          page: this.currentPage,
          from: this.fromDate,
          to: this.toDate,
          limit: 10,
        },
      })
        .then((response) => {
          this.placedorderlist = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    cancelledList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/orders/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderStatus: "Cancelled",
          page: this.currentPageCancelled,
          limit: 10,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.Cancelledlist = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    shippedList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/orders/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderStatus: "Shipped",
          page: this.currentPageShipped,
          limit: 10,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.shippedlist = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    deliveredList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/orders/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderStatus: "Delivered",
          page: this.currentPageDelivered,
          limit: 10,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.deliveredlist = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}
.custom-autocomplete .v-input__control {
  background-color: white;
  color: black;
}

.flex-g {
  background-color: #13736f;
}
.cus {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus {
  background-color: white;
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}

.cus3 {
  height: 50px;
  background-color: white;
}
.dropfont {
  font-family: mainfont;
  font-size: 14px;
  color: #000000;
  text-transform: none;
}
</style>