<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-start pl-7 class="mainfont">
          <v-flex xs3 pt-8>
            <v-btn
              width="95%"
              height="50px"
              class="custom-btn no-uppercase"
              :dark="showOrders"
              :color="showOrders ? '#13736f' : 'white'"
              @click="showOrdersComponent"
              :style="{ 'border-radius': '7px' }"
              elevation="0"
            >
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span
                    :style="
                      showOrders
                        ? 'color: white;'
                        : 'color: black; font-weight: 700;'
                    "
                    >Orders</span
                  >
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>
          <v-flex xs3 pt-8 pl-2>
            <v-btn
              width="95%"
              height="50px"
              class="custom-btn no-uppercase"
              :dark="showProducts"
              :color="showProducts ? '#13736f' : 'white'"
              @click="showProductsComponent"
              :style="{ 'border-radius': '7px' }"
              elevation="0"
            >
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span
                    :style="
                      showProducts
                        ? 'color: white; font-weight: 500;'
                        : 'color: black; font-weight: 600;'
                    "
                    >Products</span
                  >
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>
          <v-flex xs3 pl-2 pr-2 pt-8>
            <v-btn
              width="95%"
              height="50px"
              class="custom-btn no-uppercase"
              :dark="showAddProducts"
              :color="showAddProducts ? '#13736f' : 'white'"
              @click="showAddProductsComponent"
              :style="{ 'border-radius': '7px' }"
              elevation="0"
            >
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span
                    :style="
                      showAddProducts
                        ? 'color: white;'
                        : 'color: black; font-weight: 600;'
                    "
                    >Add Product</span
                  >
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>

          <v-flex xs3 pt-8 pr-3>
            <v-btn
              width="95%"
              height="50px"
              class="custom-btn no-uppercase"
              :dark="showCustomOrders"
              :color="showCustomOrders ? '#13736f' : 'white'"
              @click="showCustomOrdersComponent"
              :style="{ 'border-radius': '7px' }"
              elevation="0"
            >
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span
                    :style="
                      showCustomOrders
                        ? 'color: white;'
                        : 'color: black; font-weight: 600;'
                    "
                    >Custom Orders</span
                  >
                </v-flex>
              </v-layout>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap>
        <v-flex xs12>
          <v-layout wrap>
            <v-flex xs12>
              <orders v-if="showOrders" />
              <products v-else-if="showProducts" />
              <addp v-else-if="showAddProducts" />
              <customp v-else-if="showCustomOrders" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12> </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
  
  
  <script>
import orders from "./OrdersPage.vue";
import products from "./ProductsPage.vue";
import addp from "./AddProduct.vue";
import customp from "./CustomOrder.vue";
export default {
  components: {
    products,
    orders,
    customp,
    addp,
  },
  data() {
    return {
      preview: null,
      link: null,
      prev: null,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      showOrders: false,
      currentPage: 1,

      showProducts: false,
      showAddProducts: false,
      showCustomOrders: false,
      limit: 10,
      url: null,
      categoryName: null,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
     
  
      editdailog: false,
      curid: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.loadState();
    this.saveState();
  },
  watch: {
    showOrders: 'saveState',
    showProducts: 'saveState',
    showAddProducts: 'saveState',
    showCustomOrders: 'saveState',
  },
  created() {
    this.showOrders = true;

    this.loadState();
    if (!localStorage.getItem('pageState')) {
      this.saveState();
    }
  },
  methods: {
    saveState() {
      localStorage.setItem('pageState', JSON.stringify(this.$data));
    },
    loadState() {
      const savedState = JSON.parse(localStorage.getItem('pageState'));
      if (savedState) {
        Object.assign(this.$data, savedState);
      }
    },
    showOrdersComponent() {
      this.showOrders = true;
      this.showProducts = false;
      this.showAddProducts = false;
      this.showCustomOrders = false;
    },
    showProductsComponent() {
      this.showOrders = false;
      this.showProducts = true;
      this.showAddProducts = false;
      this.showCustomOrders = false;
    },
    showAddProductsComponent() {
      this.showOrders = false;
      this.showProducts = false;
      this.showAddProducts = true;
      this.showCustomOrders = false;
    },
    showCustomOrdersComponent() {
      this.showOrders = false;
      this.showProducts = false;
      this.showAddProducts = false;
      this.showCustomOrders = true;
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.cus {
  background-color: #13736f;
}

.custom-btn.v-btn--active {
  background-color: red !important;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.no-uppercase {
  text-transform: none !important;
}
</style>