var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-snackbar',{attrs:{"color":"black"},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#13736f","spinner":"spinner"}}),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"mainfont",attrs:{"wrap":"","justify-start":"","pl-7":""}},[_c('v-flex',{attrs:{"xs3":"","pt-8":""}},[_c('v-btn',{staticClass:"custom-btn no-uppercase",style:({ 'border-radius': '7px' }),attrs:{"width":"95%","height":"50px","dark":_vm.showOrders,"color":_vm.showOrders ? '#13736f' : 'white',"elevation":"0"},on:{"click":_vm.showOrdersComponent}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{style:(_vm.showOrders
                      ? 'color: white;'
                      : 'color: black; font-weight: 700;')},[_vm._v("Orders")])])],1)],1)],1),_c('v-flex',{attrs:{"xs3":"","pt-8":"","pl-2":""}},[_c('v-btn',{staticClass:"custom-btn no-uppercase",style:({ 'border-radius': '7px' }),attrs:{"width":"95%","height":"50px","dark":_vm.showProducts,"color":_vm.showProducts ? '#13736f' : 'white',"elevation":"0"},on:{"click":_vm.showProductsComponent}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{style:(_vm.showProducts
                      ? 'color: white; font-weight: 500;'
                      : 'color: black; font-weight: 600;')},[_vm._v("Products")])])],1)],1)],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","pr-2":"","pt-8":""}},[_c('v-btn',{staticClass:"custom-btn no-uppercase",style:({ 'border-radius': '7px' }),attrs:{"width":"95%","height":"50px","dark":_vm.showAddProducts,"color":_vm.showAddProducts ? '#13736f' : 'white',"elevation":"0"},on:{"click":_vm.showAddProductsComponent}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{style:(_vm.showAddProducts
                      ? 'color: white;'
                      : 'color: black; font-weight: 600;')},[_vm._v("Add Product")])])],1)],1)],1),_c('v-flex',{attrs:{"xs3":"","pt-8":"","pr-3":""}},[_c('v-btn',{staticClass:"custom-btn no-uppercase",style:({ 'border-radius': '7px' }),attrs:{"width":"95%","height":"50px","dark":_vm.showCustomOrders,"color":_vm.showCustomOrders ? '#13736f' : 'white',"elevation":"0"},on:{"click":_vm.showCustomOrdersComponent}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{style:(_vm.showCustomOrders
                      ? 'color: white;'
                      : 'color: black; font-weight: 600;')},[_vm._v("Custom Orders")])])],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.showOrders)?_c('orders'):(_vm.showProducts)?_c('products'):(_vm.showAddProducts)?_c('addp'):(_vm.showCustomOrders)?_c('customp'):_vm._e()],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }